import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as Constants from '../constants';
import { motion } from 'framer-motion';
import NavMain from '../components/NavMain';

// Icons
import {GiLetterBomb} from 'react-icons/gi'
import {HiOutlineSpeakerphone} from 'react-icons/hi'
import {FiShoppingCart} from 'react-icons/fi'
import {GiSlicedBread} from 'react-icons/gi'
import {CgMenuGridR} from 'react-icons/cg'
import {RiHomeSmile2Line} from 'react-icons/ri'

function GetHome() {
	// scroll to top on each page change
	const { pathname } = useLocation();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	return (
		<div className="home">
			<motion.div
			initial={{x: '-100vw'}}
			animate='in'
			exit={{x: '-100vw'}}
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			>
				<nav className="main">
					<ul className="reset flex-center flex-wrap">
						<NavMain pathname="/welcome-letter" title="Welcome letter" icon={<GiLetterBomb />} />
						<NavMain pathname="/app-notifications" title="App notifications" icon={<HiOutlineSpeakerphone />} />
						<NavMain pathname="/gourmet-boutique" title="Gourmet Boutique" icon={<FiShoppingCart />} />
						<NavMain pathname="/bread-service" title="Bread service" icon={<GiSlicedBread />} />
						<NavMain external="1" pathname="https://shop.dornsberg.net?token=cnAGKZhy3OL0" title="Access Gourmet Boutique" icon={<CgMenuGridR />} />
						<NavMain external="1" pathname="https://app.dornsberg.net?token=cnAGKZhy3OL0" title="Access Dornsberg App" icon={<CgMenuGridR />} />
						<NavMain pathname="/newsletter" title="Send mail or newsletter" icon={<RiHomeSmile2Line />} />
					</ul>
				</nav>
			</motion.div>
		</div>
	)
}

export default GetHome;