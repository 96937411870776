import React, { useState, useEffect } from 'react';
import * as Constants from '../constants';
import { motion } from 'framer-motion';
import Spinner from '../components/Spinner';
import { useForm } from 'react-hook-form';

function GetBoutique() {

	const [page, setPage] = useState('list');
	const [products, setProducts] = useState([]);
	const [purchases, setPurchases] = useState([]);
	const [stats, setStats] = useState('bestseller');
	const { register, setValue, handleSubmit, control,  formState: { errors } } = useForm();
	const [article, setArticle] = useState(false)
	const [title, setTitle] = useState('');
	const [loader, setLoader] = useState(false)
	const [apartment, setApartment] = useState('')
	const [search, setSearch] = useState('')

	let arr = [];

	useEffect(() => {
		fetchProducts();
	}, []);

	const fetchProducts = async () => {
		fetch('https://cockpit.dornsberg.net/api/collections/get/shop?token=account-9805cfff4b0ec3f5f36f57910991b1')
		.then(data => data.json())
		// push to array
		.then(data => {
			for (let i = 0; i < data.entries.length; i++) {
				arr.push(data.entries[i]);
			}
		})
		// sort array by #
		.then(() => {
			arr.sort((a, b) => a.number - b.number);
			setProducts(arr);
			// console.log(arr);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};

	const handleInput = data => {
		const typingTimeout = setTimeout(() => {
			setSearch(data.target.value)
		}, 1000);
		return () => clearTimeout(typingTimeout)
	}

	const ProductList = () => {
		return (
			<div className='product-list backend'>
				<div className="search">
					<label htmlFor="search" className="">
						Search:&nbsp;
						<input type="text" className="button2"
							autoFocus="autoFocus"
							value={search}
							onChange={(e) => {
								setSearch(e.target.value);
							}}
						/>
					</label>
					<br/><br/>
				</div>
				{products.map((product, index) => {
					if(!search || search && product['product_de'].includes(search.charAt(0).toUpperCase()+search.slice(1))) {
						return (
							<div key={index} className={product.active === true ? 'product product-'+(index+1) : 'product inactive'}>
								<button	className="t4 tal" onClick={() => {
										setArticle(product.number);
										setTitle(product.number);
										setPage('detail');
									}
								}>
									<span className="numb t4">{product.number}</span> <span className="product-name t4">{product['product_de']}</span>
								</button>
							</div>
						)
					}
				})}
			</div>
		)
	}

	const onSubmit = data => {
		fetch('https://cockpit.dornsberg.net/api/collections/save/shop?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				data: data
			})
		})
		.then(res=>res.json())
		// .then(entry => console.log(entry))
		.then(entry => fetchProducts());
	}

	const ProductDetail = () => {
		return (
			<div className="product-detail backend">
				{products.map((product, i) => {
					if(article === product.number) {
						setValue('active', product.active)
						setValue('number', product.number)
						setValue('price', product.price)
						setValue('sale', product.sale)
						setValue('expiry', product.expiry)
						setValue('sold', product.sold)
						setValue('stock', product.stock)
						setValue('_id', product._id)
						return (
							<div key={i} className={'wrapper tac product-'+(i+1)}>
								<div className="product-name t1">{product.product_de}</div>
								<img alt={product.product_de} style={{height:"320px"}} className="product-image" src={[Constants.cockpit]+product.picture.path} />
								<form onSubmit={handleSubmit(onSubmit)}>
									<label htmlFor="active">Aktiv: </label>
									<input type="text" name="active" defaultValue={product.active} {...register('active')} />
									<label htmlFor="number">Produktnummer: </label>
									<input type="number" name="number" defaultValue={product.number} {...register('number')} />
									<label htmlFor="price">Preis: </label>
									<input type="text" name="price" defaultValue={product.price} {...register('price')} />
									<label htmlFor="sale">Sale: </label>
									<input type="text" name="sale" defaultValue={product.sale} {...register('sale')} />
									<label htmlFor="expiry">Verfallsdatum: </label>
									<input type="text" name="expiry" defaultValue={product.expiry} {...register('expiry')} />
									<label htmlFor="sold">verkauft: </label>
									<input type="number" name="sold" defaultValue={product.sold} {...register('sold')} />
									<label htmlFor="stock">Lagerbestand: </label>
									<input type="number" name="stock" defaultValue={product.stock} {...register('stock')} />

									<input type="hidden" name="_id" value={product._id} {...register('_id')} />
									<input type="submit" value="speichern" className="submit button2" />
									<button className="button2" onClick={() => setPage('list')+setSearch('')}>Zurück</button>
								</form>
							</div>
						)
					}
				})}
			</div>
		)
	}

	const fetchPurchases = async () => {
		fetch('https://cockpit.dornsberg.net/api/forms/export/shop?token=account-9805cfff4b0ec3f5f36f57910991b1', {
			method: 'post',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(data => data.json())
		.then(data => {
			setPurchases(data);
			// console.log(data);
		})
		.catch(function() {
			console.log('Fetch error');
		});
	};


	// show all purchases chronological
	const Purchases = () => (
		purchases.map((item, i) => {
			let timestamp = new Date(item._created*1000);
			const purchaseSum = item.data.order.reduce((sum, item) => {
				if(item.product.sale) return sum + (Number(item.product.sale.replaceAll(',','.')) * item.quantity)
				else return sum + (Number(item.product.price.replaceAll(',','.')) * item.quantity)
			},0)
			let purchaseTotal = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(purchaseSum);
			return (
				<div key={i} className="purchase">
					<div className="purchase-date">{timestamp.toLocaleDateString('de', {weekday: 'long'})}, {timestamp.toLocaleDateString('de')} um {timestamp.toLocaleTimeString('de')} Uhr</div>
					<div className="purchase-apt">Apartment: <span className="bold medium">{item.data.apartment}</span></div>
					<div className="purchases">
						{item.data.order.map((order, index) => (
							<div key={index} className="order">
								{order.quantity}x {order.product.product_de}
							</div>
						))}
					</div>
					<div className="purchase-total">Summe: {purchaseTotal}</div>
				</div>
			)
		})
	)

	const TotalRevenue = () => {
		let totalRevenue = 0;
		purchases.map((item, i) => {
			const purchaseSum = item.data.order.reduce((sum, item) => {
				if(item.product.sale) return sum + (Number(item.product.sale.replaceAll(',','.')) * item.quantity)
				else return sum + (Number(item.product.price.replaceAll(',','.')) * item.quantity)
			},0)
			// total revenue
			totalRevenue += purchaseSum
		})
		return (
			<div className='t4 large'>
				Umsatz total: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totalRevenue)}
			</div>
		)
	}

	const Backend = () => {
		if(!purchases.length) {
			fetchPurchases();
			return <Spinner />;
		}
		else {
			// sort array by timestamp
			purchases.sort((a, b) => b._created - a._created)
			return (
				<div className="purchases-list">
					<nav className="purchase-filter">
						<button className={apartment === '' ? 'button2 active' : 'button2'} onClick={() => {fetchPurchases(); setApartment('')}}>Alle</button>
						<button className={apartment === 'Frieda' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Frieda'); setPurchases(purchases.filter(item => item.data.apartment === 'Frieda'))}}>Frieda</button>
						<button className={apartment === 'Paula' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Paula'); setPurchases(purchases.filter(item => item.data.apartment === 'Paula'))}}>Paula</button>
						<button className={apartment === 'Martha' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Martha'); setPurchases(purchases.filter(item => item.data.apartment === 'Martha'))}}>Martha</button>
						<button className={apartment === 'Franzi' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Franzi'); setPurchases(purchases.filter(item => item.data.apartment === 'Franzi'))}}>Franzi</button>
						<button className={apartment === 'Luise' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Luise'); setPurchases(purchases.filter(item => item.data.apartment === 'Luise'))}}>Luise</button>
						<button className={apartment === 'Lotte' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Lotte'); setPurchases(purchases.filter(item => item.data.apartment === 'Lotte'))}}>Lotte</button>
						<button className={apartment === 'Greta' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Greta'); setPurchases(purchases.filter(item => item.data.apartment === 'Greta'))}}>Greta</button>
						<button className={apartment === 'Anni' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Anni'); setPurchases(purchases.filter(item => item.data.apartment === 'Anni'))}}>Anni</button>
						<button className={apartment === 'Marie' ? 'button2 active' : 'button2'} onClick={() => {setApartment('Marie'); setPurchases(purchases.filter(item => item.data.apartment === 'Marie'))}}>Marie</button>
					</nav>
					<TotalRevenue />
					<br/>
					<Purchases />
				</div>
			)
		}
	}

	const Bestseller = () => {
		let bestseller = products.sort((a, b) => b.sold - a.sold)
		return (
			<div className="bestseller">
				{bestseller.map((product, i) => (
					<div key={i} className="stats-item">
						{i+1}. {product.product_de}: {product.sold}
					</div>
				))}
			</div>
		)
	}

	const CashCow = () => {
		let cashCow = products.sort((a, b) => (Number(b.price.replaceAll(',','.')) * b.sold) - (Number(a.price.replaceAll(',','.')) * a.sold))
		return (
			<div className="revenue">
				{cashCow.map((product, i) => {
					let revenue = (Number(product.price.replaceAll(',','.')) * product.sold)
					return (
						<div key={i} className="stats-item">
							{i+1}. {product.product_de}: {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(revenue)}
						</div>
					)
				})}
			</div>
		)
	}

	const Stock = () => {
		let stock = products.sort((a, b) => b.stock - a.stock)
		return (
			<div className="stock">
				{stock.map((product, i) => (
					<div key={i} className="stats-item">
						{i+1}. {product.product_de}: {product.stock}
					</div>
				))}
			</div>
		)
	}

	const Expiry = () => {
		// create new product list with timestamp
		let copy = JSON.parse(JSON.stringify(products));
		copy.map((product, i) => {
			let expiryDate = product.expiry.replaceAll('.','-');
			expiryDate = expiryDate.split("-");
			let timestamp = new Date(expiryDate[2], expiryDate[1] - 1, expiryDate[0]);
			timestamp = timestamp.getTime();
			copy[i].expiryTimestamp = timestamp;
		})
		let expiry = copy.sort((a, b) => a.expiryTimestamp - b.expiryTimestamp)
		if(expiry === '') return <Spinner />;
		else return (
			<div className="expiry">
				{expiry.map((product, i) => (
					<div key={i} className="stats-item">
						{i+1}. {product.product_de}: {product.expiry}
					</div>
				))}
			</div>
		)
	}

	const Stats = () => {
		if(products === '') return <Spinner />;
		else return (
			<div className="stats">
				<div className="product-nav">
					<button className={stats === 'bestseller' ? 'button2 active' : 'button2'} onClick={() => setStats('bestseller')}>Bestseller</button>
					<button className={stats === 'cashcow' ? 'button2 active' : 'button2'} onClick={() => setStats('cashcow')}>Cash Cow</button>
					<button className={stats === 'stock' ? 'button2 active' : 'button2'} onClick={() => setStats('stock')}>Lagerbestand</button>
					<button className={stats === 'expiry' ? 'button2 active' : 'button2'} onClick={() => setStats('expiry')}>Verfallsdatum</button>
				</div>
				{stats === 'bestseller' ? <Bestseller /> : ''}
				{stats === 'cashcow' ? <CashCow /> : ''}
				{stats === 'stock' ? <Stock /> : ''}
				{stats === 'expiry' ? <Expiry /> : ''}
			</div>
		)
	}

	return (
		<motion.div
			initial='initial'
			animate='in'
			exit='out'
			variants={Constants.pageTransition}
			style={Constants.pageTransition.style}
			transition={Constants.pageTransition.transition}
			className='gourmet-boutique'>
				<div className="product-nav">
					<button className={page === 'list' ? 'button2 active' : 'button2'} onClick={() => setPage('list')}>Produktliste</button>
					<button className={page === 'backend' ? 'button2 active' : 'button2'} onClick={() => setPage('backend')}>Alle Einkäufe</button>
					<button className={page === 'stats' ? 'button2 active' : 'button2'} onClick={() => setPage('stats')}>Statistiken</button>
				</div>
				{loader ? <Spinner /> : ''}
				{page === 'list' ? <ProductList /> : ''}
				{page === 'detail' ? <ProductDetail /> : ''}
				{page === 'backend' ? <Backend /> : ''}
				{page === 'stats' ? <Stats /> : ''}
		</motion.div>
	)
}

export default GetBoutique;